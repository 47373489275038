.Page {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5mm;
  break-inside: avoid;
  padding: 7mm;
  box-sizing: border-box;
}


@media screen {
  .Page {
    width: 297mm;
    height: 210mm;
    outline: 1px dashed grey;
  }
}