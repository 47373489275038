@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Merriweather&display=swap');

@font-face {
  font-family: 'Trajan Pro';
  src: url('./fonts/TrajanPro-Regular.ttf') format('truetype');
}

@page {
  dpi: 600;
  width: 297mm;
  height: 210mm;
}

@media print {
  .no-print {
    display: none !important;
  }
}

@media screen {
  .no-screen {
    display: none !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  display: flex;
  justify-content: space-around;
  font-family: 'Times New Roman';
}
p, small {
  font-size: 7pt;
  font-family: 'Merriweather';
}

p {
  margin: 0.5rem 0.2rem
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Trajan Pro';
  margin: 0;
  letter-spacing: 1pt;
}
h1 {
  font-size: 1rem;
}
h2 {
  font-size: 0.7rem;
}

small {
  color: grey;
  font-style: italic;
}