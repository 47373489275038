.Card {
  width: 64mm;
  height: 90mm;
  padding: 1rem;
  box-sizing: border-box;
  break-inside: avoid;

  border-image-slice: 62 62 62 62;
  border-image-width: 20px 20px 20px 20px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: repeat repeat; 
  border-image-source: url('../images/background.png');

  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.Card .charges > h2 {
  margin-bottom: 0.5rem;
}

.Card .charges > div {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  margin-bottom: 0.5rem;
}

.Card .charges > div > div {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1pt solid #333;
}

@media screen {
  .Card {
    outline: 1px dashed grey;
  }
}